$(document).ready(function () {

    let cardsComponentSliderOptions = {
        arrows: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 2,
        dots: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },{
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
              }
            },
            {
                breakpoint: 576,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false
              }
            }
        ]
    };

    $(".js-slider-cards-component").each(function () {
        if ($(this).hasClass("inizialized") || $(window).width() < 576) return;

        if ($(this).find(".slider-cards-component__slide").length > 3) {
            if($(this).hasClass("center-mode")){
                // no arrows, previous and next item partially show
                cardsComponentSliderOptions.centerMode = true;
                cardsComponentSliderOptions.variableWidth = true;
                $(this).parent().removeClass("container container-sm-0").addClass("container-fluid px-5")
            }else{
                // default slider with arrow and NO previous and next item partially show
                cardsComponentSliderOptions.arrows = true;
            }

            $(this).slick(cardsComponentSliderOptions);
            $(this).addClass("inizialized");
        }
    });

    let cardsIconsOptions = {
        settings: "unslick",
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: true,
                }
            }
        ]
    };

    $(".js-slider-tpl_icons").each(function () {
        if ($(this).hasClass("inizialized") || $(window).width() > 768) return;
        if ($(this).find(".slider-cards-component__slide")) {
            $(this).slick();
            $(this).addClass("inizialized");
        }

    });

    $(".cmp_cards .listingItems").each(function () {
        const isDataMobileHorizontalScroll = Boolean($(this).data("mobilehorizontalscroll"));
        if(($(window).width() < 576)){
            if( isDataMobileHorizontalScroll ){
                $(this).find(".item").addClass("col-12 col-sm")
                cardsComponentSliderOptions = {
                    arrows : true,
                    dots : !$(this).hasClass("center-mode"),
                    slidesToShow : 1,
                    draggable : true,
                    swipe : true
                }
                $(this).slick(cardsComponentSliderOptions);
            }
        }
    });

    $(".js-slider-custom-cards").each(function () {
        if ($(this).hasClass("inizialized")) return;
        if ($(this).find(".slider-cards-component__slide").length > 3) {
            $(this).slick();
            $(this).addClass("inizialized");
        }
    });

    $(".js-slider-tpl_icons").each(function () {
        if ($(this).hasClass("inizialized") || $(window).width() > 576) return;
        $(this).slick();
        $(this).addClass("inizialized");
    });

    $('.js-slide-card').slick({
        dots: true,
        arrows: false,
        slidesToShow: 1,
        draggable: false,
        swipe:false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    swipe:false,
                }
            },{
                breakpoint: 992,
                settings: {
                    swipe:false,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    swipe:false,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    swipe:true,
                }
            }
        ]
    });
})
